<template>
    <b-btn @click="active = !active" :variant="active ? activeVariant : variant">
        <slot></slot>
    </b-btn>
</template>

<script>
export default {
    name: `btn-toggle`,
    props: {
        value: {type: Boolean},
        activeVariant: {type: String, default: `primary`},
        variant: {type: String, default: `white`}
    },
    computed: {
        active: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
